<template>
  <div class="course">
    <a-spin :spinning="spinning">
        <a-result v-if="list.length==0" status="404" title="没有课程" sub-title="还没有需要你审核的课程！">
            <template #extra>
            </template>
        </a-result>
      <ul class="courses">
        <course-item
          class="course-item"
          v-for="(item, index) in list"
          :key="index"
          :index="index"
          :item="item"
          :showDown="false"
          @navigateToDetail="navigateToDetail(item)"
        >
        </course-item>
      </ul>
    </a-spin>
  </div>
</template>

<script>

import { getAllLessonHall } from "@/api/review";
import CourseItem from '../../components/CourseItem.vue';
import { getIconWithType } from "@/utils/util"
export default {
  components: { CourseItem },
  name: "course",
  data() {
    return {
      list: [],
      spinning: false,
    };
  },
  mounted() {
    this.requestMyLesson();
  },
  methods: {
    requestMyLesson() {
      this.spinning = true;
      getAllLessonHall().then((res) => {
        console.log("res", res);
        this.spinning = false;
        if (res.code == 200) {
            if(res.data.hallGroup) {
                res.data.hallGroup.forEach((element) => {
                    element.icon = getIconWithType(element.Type);
                });
                this.list = res.data.hallGroup;
            }
        }
      });
    },
    navigateToDetail(item) {
      this.$router.push({ name: "partlist", query: { course: item.Type } });
    },
  },
};
</script>

<style lang="less">
.course {
    .courses {
        padding: 50px 0;
        display: flex;
        flex-wrap: wrap;
        min-height: 400Px;
        
    }
}
</style>